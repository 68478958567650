import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormSelectField } from "../../../../core/CustomFormFields";
import { Field, Formik } from "formik";
import { languagesDataForTranslate } from "../OCRDrawer/LanguagesData";
import * as Yup from "yup";

import { getTranslateData } from "../../../../../services/translate";
import "./translateDrawer.css";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  language: Yup.string().required("Please select a language"),
});

const TranslateDrawer = ({
  isOpenTranslate,
  handleClose,
  translateName,
  createAiRiskAssessment,
  tokenId,
  setOldButtonList,
  originalUrl,
  setOriginalList,
  setOriginalData,
  setPdfUrl,
}) => {
  const [convertTranslateLoading, setConvertTranslateLoading] = useState(false);

  const initialValues = {
    language: "eng",
  };

  const reportName =
    translateName === "Company Registration"
      ? "poi"
      : translateName === "UBO Certificate" && "ubo";

  const signalRef = useRef(null);

  const handleFormSubmit = (values) => {
    if (translateName !== "Risk Assessment Report") {
      signalRef.current = new AbortController();
      const signal = signalRef.current.signal;

      setConvertTranslateLoading(true);

      getTranslateData(
        {
          lang: values.language,
          token_id: tokenId,
          docType: reportName,
          fileName: originalUrl,
        },
        signal
      )
        .then((response) => {
          const res = response.data;
          if (response?.error) {
            setConvertTranslateLoading(false);
            return message.error(
              "Something went wrong creating translation, please try again"
            );
          }

          res["isAddedNow"] = true;

          setOriginalList((prevState) => ({
            ...prevState,
            [reportName]: [...prevState[reportName], res],
          }));

          setPdfUrl(res.translatedDoc);

          setOriginalData((prevState) => ({
            ...prevState,
            [reportName]: res,
          }));

          setOldButtonList((prevState) => ({
            ...prevState,
            [reportName]: res,
          }));

          message.success("Translation created successfully");

          handleClose();
          setConvertTranslateLoading(false);
        })
        .catch((error) => {
          setConvertTranslateLoading(false);
          return message.error(
            "Something went wrong creating translation, please try again"
          );
        });
    } else {
      handleClose();
      createAiRiskAssessment(values?.language);
    }
  };

  useEffect(() => {
    return () => {
      signalRef.current?.abort();
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, setFieldValue, values, errors, touched }) => (
        <Drawer
          placement="right"
          className="translateSelectDrawer select-none"
          closable={false}
          onClose={handleClose}
          open={isOpenTranslate}
          title={
            <div className="flex justify-between items-center">
              <p className="text-xl font-bold">{translateName}</p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          }
          footer={
            <div className="flex justify-end items-center gap-3">
              <Button type="primary" ghost onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className="flex"
                type="primary"
                onClick={() => handleSubmit()}
                loading={convertTranslateLoading}
              >
                Create
              </Button>
            </div>
          }
        >
          <div className="bg-[#fff] w-full rounded-xl shadow-md sm:p-5 p-4 flex gap-4 flex-col">
            <div className="flex flex-col">
              <div className="flex justify-between items-center gap-2 mb-2">
                <p className="text-[#000] font-extrabold text-sm flex gap-1">
                  <span className="text-red-500">*</span>Create into
                </p>

                <p className="text-[#000] font-semibold text-sm">
                  <span>{values.language ? 1 : 0}</span>/<span>1</span>
                </p>
              </div>

              <Form onFinish={handleSubmit}>
                <Field name="language">
                  {({ field }) => (
                    <div>
                      <FormSelectField
                        {...field}
                        value={field.value}
                        onChange={(selected) =>
                          setFieldValue("language", selected)
                        }
                        showSearch={true}
                        options={languagesDataForTranslate.map((lang) => ({
                          label: lang.label,
                          value: lang.value,
                        }))}
                        placeholder="Select Language"
                        virtual={false}
                      />
                      {touched.language && errors.language ? (
                        <div className="text-red-500 text-xs font-bold mt-1 ml-1">
                          {errors.language}
                        </div>
                      ) : null}
                    </div>
                  )}
                </Field>
              </Form>
            </div>
          </div>
        </Drawer>
      )}
    </Formik>
  );
};

export default TranslateDrawer;
