import { useEffect } from "react";
import { Table } from "antd";
import {
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
  AiOutlineReload,
} from "react-icons/ai";
import "./index.css";

const TableReportGridAdmin = ({
  setSearchText,
  PDF,
  keepChanges = false,
  isTableLoading,
  data,
  setData,
  setClickedCell,
  clickedCell,
  handleCleanSearch,
}) => {
  useEffect(() => {
    handleCleanSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PDF]);

  const handleChange = (value, record, column, fieldId) => {
    const newData = data.map((item) => {
      if (item.key === record.key) {
        const updatedFields = item[column].map((field) =>
          field.id === fieldId ? { ...field, value } : field
        );
        return { ...item, [column]: updatedFields };
      }
      return item;
    });
    setData(newData);
  };

  const handleRevert = (record, column, fieldId) => {
    const newData = data.map((item) => {
      if (item.key === record.key) {
        const revertedFields = item[column].map((field) =>
          field.id === fieldId ? { ...field, value: field.original } : field
        );
        return { ...item, [column]: revertedFields };
      }
      return item;
    });
    setData(newData);
  };

  const handleRemoveField = (record, column, fieldId) => {
    const newData = data.map((item) => {
      if (item.key === record.key) {
        const filteredFields = item[column].filter(
          (field) => field.id !== fieldId
        );
        return { ...item, [column]: filteredFields };
      }
      return item;
    });
    setData(newData);
  };

  const handleAddField = (record, column, id) => {
    const newData = data.map((item) => {
      if (item.key === record.key) {
        const newField = {
          id: id + 1,
          value: "",
          original: "",
        };
        return { ...item, [column]: [...item[column], newField] };
      }
      return item;
    });
    setData(newData);
  };

  const renderCell = (
    cellItems,
    type,
    index,
    selectItem,
    checkLength,
    record
  ) => {
    const itemValue = cellItems.value;
    const itemOriginal = cellItems.original;
    const itemId = cellItems.id;

    const isActive =
      clickedCell.name === itemValue &&
      clickedCell.key === selectItem.key &&
      clickedCell.type === selectItem.type;

    const isClickable =
      itemValue !== "" &&
      itemValue?.substring(0, 3) !== "N/A" &&
      type !== "check" &&
      !keepChanges;

    const cellClass =
      isActive && isClickable
        ? "bg-[#FFFF99]"
        : itemValue === "Inconsistent"
        ? "bg-[#FE9690] bg-opacity-80"
        : "bg-[#f0f6fd]";
    const onClick = () => {
      if (isClickable) {
        setSearchText({
          type: type,
          colKey: selectItem.type,
          value: itemValue,
        });
        setClickedCell({
          name: itemValue,
          key: selectItem.key,
          type: selectItem.type,
        });
      }
    };

    return (
      <div
        key={index}
        className={`${cellClass} ${
          keepChanges ? "p-2" : "p-[17px]"
        } min-h-[46] h-max w-full flex gap-1 items-center cursor-pointer`}
        onClick={onClick}
      >
        {type === "check" ? (
          <span
            className="h-full w-full flex items-center justify-center"
            style={{ wordBreak: "break-word" }}
          >
            {itemValue}
          </span>
        ) : keepChanges ? (
          <div className="w-full flex justify-start gap-1.5 items-center">
            <input
              value={itemValue}
              onChange={(e) =>
                handleChange(e.target.value, record, selectItem.type, itemId)
              }
              className={` h-auto px-2 py-1 rounded focus:bg-gray-200  bg-gray-100
              `}
            />
            {itemValue !== itemOriginal && (
              <div>
                <AiOutlineReload
                  className="text-base text-[#311c7e] cursor-pointer"
                  onClick={() => handleRevert(record, selectItem.type, itemId)}
                />
              </div>
            )}
            {(type === "UBOs" || type === "Directors/Officers") && (
              <div className={`flex gap-1.5`}>
                {checkLength !== 1 && (
                  <AiOutlineMinusCircle
                    className="text-base text-red-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveField(record, selectItem.type, itemId);
                    }}
                  />
                )}

                {checkLength === index + 1 && (
                  <AiOutlinePlusCircle
                    className="text-base text-[#2020c3] cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddField(record, selectItem.type, itemId);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div style={{ wordBreak: "break-word" }}>
            {type === "Directors/Officers" || type === "UBOs" ? (
              <>
                {itemValue.split(",").length > 1 ? (
                  <>
                    <div>
                      {itemValue.split(",")[0].trim && (
                        <div style={{ wordBreak: "break-word" }}>
                          {itemValue.split(",")[0].trim()}
                          {","}
                        </div>
                      )}
                    </div>
                    <div>
                      {itemValue.split(",")[1].trim && (
                        <div style={{ wordBreak: "break-word" }}>
                          {itemValue.split(",")[1].trim()}
                          {itemValue.split(",")[2].trim && (
                            <>
                              {", "}
                              {itemValue.split(",")[2].trim()}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div style={{ wordBreak: "break-word" }}> {itemValue}</div>
                )}
              </>
            ) : (
              // itemValue
              //     .split(",")
              //     .map((val, index) => <div key={index}>{val.trim()}</div>)
              itemValue
            )}
          </div>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        const colSpan = record.property === "blankRow" ? 5 : 1;
        return {
          children:
            record.property === "blankRow" ? (
              <div className="h-3"></div>
            ) : (
              <div className="p-3 flex items-start">{text}</div>
            ),
          props: {
            colSpan,
          },
        };
      },
    },
    {
      title: (
        <div>
          <div>Details from CDD Report</div>
          <div>(Self declared)</div>
        </div>
      ),
      dataIndex: "cddReport",
      key: "cddReport",
      render: (cddReport, record) => {
        const colSpan = record.property === "blankRow" ? 0 : 1;
        return {
          children:
            record.property === "blankRow" ? (
              <div className="h-3"></div>
            ) : (
              <div className="my-3 flex flex-col gap-3 w-full break-all">
                {cddReport.map((item, index) =>
                  renderCell(
                    item,
                    record.name,
                    index,
                    {
                      key: record.key,
                      type: "cddReport",
                    },
                    cddReport.length,
                    record
                  )
                )}
              </div>
            ),
          props: {
            colSpan,
          },
        };
      },
    },
    {
      title: "Details from Company Information Report",
      dataIndex: "companyRegistration",
      key: "companyRegistration",
      render: (companyRegistration, record) => {
        const colSpan = record.property === "blankRow" ? 0 : 1;
        return {
          children:
            record.property === "blankRow" ? (
              <div className="h-3"></div>
            ) : (
              <div className="my-3 flex flex-col gap-3 w-full break-all">
                {companyRegistration.map((item, index) =>
                  renderCell(
                    item,
                    record.name,
                    index,
                    {
                      key: record.key,
                      type: "companyRegistration",
                    },
                    companyRegistration.length,
                    record
                  )
                )}
              </div>
            ),
          props: {
            colSpan,
          },
        };
      },
    },
    {
      title: "Details from Company UBO Certificate",
      dataIndex: "UBOCertificate",
      key: "UBOCertificate",
      render: (UBOCertificate, record) => {
        const colSpan = record.property === "blankRow" ? 0 : 1;
        return {
          children:
            record.property === "blankRow" ? (
              <div className="h-3"></div>
            ) : (
              <div className="my-3 flex flex-col gap-3 w-full break-all">
                {UBOCertificate.map((item, index) =>
                  renderCell(
                    item,
                    record.name,
                    index,
                    {
                      key: record.key,
                      type: "UBOCertificate",
                    },
                    UBOCertificate.length,
                    record
                  )
                )}
              </div>
            ),
          props: {
            colSpan,
          },
        };
      },
    },
    {
      title: "Consistency",
      dataIndex: "checks",
      key: "checks",
      width: 120,
      render: (checks, record) => {
        const colSpan = record.property === "blankRow" ? 0 : 1;
        return {
          children:
            record.property === "blankRow" ? (
              <div className="h-3"></div>
            ) : (
              <div className="my-3 flex flex-col gap-3 break-all">
                {checks.map((item, index) =>
                  renderCell(item, "check", index, {
                    key: record.key,
                    type: "checks",
                  })
                )}
              </div>
            ),
          props: {
            colSpan,
          },
        };
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered={false}
      loading={isTableLoading}
      scroll={{ y: 600 }}
      className="customTable border border-[#8080809f] rounded-sm select-none"
    />
  );
};

export default TableReportGridAdmin;
