/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import PDFParser from "../../../PDFParsing/index";
import { Button, Drawer, message, Spin } from "antd";
import "./AiReportViewerDrawer.css";
import { IoMdClose } from "react-icons/io";
import {
  getGridData,
  getPdfData,
  getRequestMail,
  saveGridData,
} from "../../../../services/admin-ai-viewer";
import { baseUrl } from "../../../../services/services.profile";
import TableReportGridAdmin from "../../../ui/CustomTable/TableReportGridAdmin";
import OCRSelectDrawer from "./OCRDrawer/OCRSelectDrawer";
import * as pdfjsLib from "pdfjs-dist/webpack";
import TranslateDrawer from "./translateDrawer/translateDrawer";
import { lablesMapping } from "../../../lablesMappingData";
import { getLabelByValue } from "./OCRDrawer/LanguagesData";
import {
  cancelTranslateData,
  saveTranslateData,
} from "../../../../services/translate";
const AdminAiViewerDrawer = ({
  isOpen,
  handleClose,
  singleRecordData,
  getOnBoardingApi,
}) => {
  const [data, setData] = useState([]);
  const [discard, setDiscard] = useState([]);
  const [buttonList, setButtonList] = useState([]);
  const [response, setResponse] = useState();
  const [searchText, setSearchText] = useState(null);
  const [translateName, setTranslateName] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [PDF, setPdfUrl] = useState("");
  const [originalUrl, setOriginalUrl] = useState("");
  const [observations, setObservations] = useState("");
  const [activeButton, setActiveButton] = useState("CDD Report");
  const [keepChanges, setKeepChanges] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [clearHighlights, setClearHighlights] = useState(false);
  const [isOpenOCR, setIsOpenOCR] = useState(false);
  const [saveTranslateLoading, setSaveTranslateLoading] = useState(false);
  const [isOpenTranslate, setIsOpenTranslate] = useState(false);
  const [pdfContainsText, setPdfContainsText] = useState(false);
  const [oldButtonList, setOldButtonList] = useState({
    poi: null,
    ubo: null,
  });

  const [requestLoading, setRequestLoading] = useState({
    UBO: false,
    POI: false,
    CDD: false,
  });

  const [clickedCell, setClickedCell] = useState({
    name: "",
    key: "",
    type: "",
  });

  const [originalList, setOriginalList] = useState({
    poi: [],
    ubo: [],
  });

  const [originalData, setOriginalData] = useState({
    poi: {},
    ubo: {},
  });

  const { tokenId, companyName } = singleRecordData;

  const transformData = (data) => {
    const finalData = data.map((item) => ({
      key: item.key,
      name: item.categoryName,
      property: item.property,
      cddReport: item.cdd_doc.map((report, index) => ({
        id: index + 1,
        value: report,
        original: report,
      })),
      UBOCertificate: item.ubo_doc.map((ubo, index) => ({
        id: index + 1,
        value: ubo,
        original: ubo,
      })),
      companyRegistration: item.id_doc.map((reg, index) => ({
        id: index + 1,
        value: reg,
        original: reg,
      })),
      checks: item.consistency.map((check, index) => ({
        id: index + 1,
        value: check,
        original: check,
      })),
    }));
    finalData.splice(6, 0, {
      key: 15,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });
    finalData.splice(8, 0, {
      key: 16,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });
    finalData.splice(10, 0, {
      key: 17,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });

    return finalData;
  };

  const checkType = (item) => {
    if (!item) return [];
    return typeof item === "string" ? [item] : item;
  };
  const handleCleanSearch = () => {
    setSearchText("");
    setClickedCell({
      name: "",
      key: "",
      type: "",
    });
    setClearHighlights(true);
  };

  const checkPdfForText = async (pdfUrl) => {
    const loadingTask = pdfjsLib.getDocument(baseUrl + "/" + pdfUrl);
    try {
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;

      let hasText = true;

      for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        if (textContent.items.length > 0) {
          hasText = false;
          break;
        }
      }
      setPdfContainsText(hasText);
    } catch (error) {
      setPdfContainsText(false);
    }
  };

  const handleCancelTranslation = () => {
    if (!tokenId) {
      return;
    }

    const translatedData =
      selectedButton === "poi"
        ? oldButtonList.poi
        : selectedButton === "ubo" && oldButtonList.ubo;

    const originalSelectedViewList =
      selectedButton === "poi"
        ? originalList.poi
        : selectedButton === "ubo" && originalList.ubo;

    const ignoreCallCancelAPI =
      !!originalSelectedViewList.find(
        (x) =>
          !x.isAddedNow &&
          x.showAdmin &&
          x.translatedTo === translatedData?.translatedTo
      ) || translatedData?.showUser;

    (ignoreCallCancelAPI
      ? Promise.resolve()
      : cancelTranslateData({
          pdfName: translatedData?.translatedDoc,
        })
    ).then(() => {
      setPdfUrl(originalUrl);
      setOldButtonList((prevState) => ({
        ...prevState,
        [selectedButton]: null,
      }));

      setOriginalList((prevState) => ({
        ...prevState,
        [selectedButton]: prevState[selectedButton].slice(
          0,
          prevState[selectedButton].length - 1
        ),
      }));

      setOriginalData((prevState) => ({
        ...prevState,
        [selectedButton]: {},
      }));
    });
  };

  const handleSaveTranslation = () => {
    if (!tokenId) {
      return;
    }

    setSaveTranslateLoading(true);

    const translatedDocData =
      selectedButton === "poi"
        ? originalData.poi
        : selectedButton === "ubo" && originalData.ubo;

    const payload = {
      token_id: tokenId,
      docType: selectedButton,
      translatedFrom: translatedDocData.translatedFrom,
      translatedTo: translatedDocData.translatedTo,
      originalDoc: translatedDocData.originalDoc,
      translatedDoc: translatedDocData.translatedDoc,
    };

    saveTranslateData(payload)
      .then((res) => {
        if (res?.error) {
          setSaveTranslateLoading(false);
          return message.error("Translation saving failed, please try again");
        }
        message.success("Translation saved successfully");

        setOldButtonList((prevState) => ({
          ...prevState,
          [selectedButton]: null,
        }));
      })
      .catch((error) => {
        console.log(error);
        message.error("Translation saving failed, please try again");
      })
      .finally(() => {
        setSaveTranslateLoading(false);
      });
  };

  useEffect(() => {
    if (activeButton !== "CDD Report" && PDF) {
      checkPdfForText(PDF);
    } else if (activeButton === "CDD Report") {
      setPdfContainsText(false);
    }
  }, [PDF, activeButton]);

  useEffect(() => {
    if (response) {
      const transformedData = transformData(response);
      setData(transformedData);
      setDiscard(transformedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const handleDiscardChanges = () => {
    setData(discard);
    setKeepChanges(false);
    setDiscardChanges(false);
  };

  useEffect(() => {
    if (data.length !== 0) {
      if (keepChanges) {
        handleCleanSearch();
      } else {
        setDiscard(data);
        setData(syncOriginalWithValue(data));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keepChanges]);

  const syncOriginalWithValue = (data) => {
    return data.map((item) => ({
      ...item,
      cddReport: item.cddReport.map((field) => ({
        ...field,
        original: field.value,
      })),
      UBOCertificate: item.UBOCertificate.map((field) => ({
        ...field,
        original: field.value,
      })),
      companyRegistration: item.companyRegistration.map((field) => ({
        ...field,
        original: field.value,
      })),
      checks: item.checks.map((field) => ({
        ...field,
        original: field.value,
      })),
    }));
  };

  useEffect(() => {
    if (!tokenId) {
      return message.error("Token id missing");
    }
    setIsPdfLoading(true);
    getPdfData(tokenId)
      .then((res) => {
        if (res?.error) {
          setIsPdfLoading(false);
          return message.error(res?.error?.message);
        }

        setPdfContainsText(res?.pdfHasText || false);
        setButtonList([
          {
            name: "CDD Report",
            fileName: res?.userCertificateData?.newCdd?.fileName
              ? res?.userCertificateData?.newCdd?.fileName
              : "",
          },
          {
            name: "Company Registration",
            fileName:
              "poiOcr" in res?.userCertificateData &&
              res?.userCertificateData?.poiOcr
                ? res?.userCertificateData?.poiOcr
                : res?.userCertificateData?.newPoi?.fileName
                ? res?.userCertificateData?.newPoi?.fileName
                : "",
          },
          {
            name: "UBO Certificate",
            fileName:
              "uboOcr" in res?.userCertificateData &&
              res?.userCertificateData?.uboOcr
                ? res?.userCertificateData?.uboOcr
                : res?.userCertificateData?.newUbo?.fileName
                ? res?.userCertificateData?.newUbo?.fileName
                : "",
          },
        ]);

        setPdfUrl(
          res?.userCertificateData?.newCdd?.fileName
            ? res?.userCertificateData?.newCdd?.fileName
            : ""
        );

        // Check if translatedPoi key exists and it's not empty, then filter
        const filteredTranslatedPoi =
          res.userCertificateData.translatedPoi &&
          res.userCertificateData.translatedPoi.length > 0
            ? res.userCertificateData.translatedPoi.filter(
                (item) => item.showAdmin
              )
            : [];

        // Check if translatedUbo key exists and it's not empty, then filter
        const filteredTranslatedUbo =
          res.userCertificateData.translatedUbo &&
          res.userCertificateData.translatedUbo.length > 0
            ? res.userCertificateData.translatedUbo.filter(
                (item) => item.showAdmin
              )
            : [];

        setOriginalList({
          poi: filteredTranslatedPoi,
          ubo: filteredTranslatedUbo,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPdfLoading(false);
      });
  }, [tokenId]);

  const dataSet = (res) => {
    const dataset = Object.keys(lablesMapping).map((key, index) => {
      const data = res[key] || {};
      return {
        key: index + 1,
        property: key,
        categoryName: lablesMapping[key],
        cdd_doc: checkType(data.cdd_doc || "N/A"),
        id_doc: checkType(data.id_doc || "N/A"),
        ubo_doc: checkType(data.ubo_doc || "N/A"),
        consistency: checkType(data.consistency || "N/A"),
      };
    });
    setObservations(res["brief_risk_assessment"]);

    setResponse(dataset);
  };

  const createGrid = () => {
    if (!tokenId) {
      return message.error("Token id missing");
    }

    let missingDocs = [];
    if (!buttonList[0].fileName) missingDocs.push("CDD");
    if (!buttonList[1].fileName) missingDocs.push("POI");
    if (!buttonList[2].fileName) missingDocs.push("UBO");

    if (missingDocs.length > 0) {
      // Format the message to include commas and "and" before the last item
      let formattedMessage =
        missingDocs.length > 1
          ? `${missingDocs.slice(0, -1).join(", ")} and ${missingDocs.slice(
              -1
            )}`
          : missingDocs[0];

      return message.error(
        `${formattedMessage} document${
          missingDocs.length > 1 ? "s" : ""
        } is required`
      );
    }

    setKeepChanges(false);
    setIsTableLoading(true);
    handleCleanSearch();

    getGridData({ token_id: tokenId })
      .then((res) => {
        if (res?.error) {
          setIsTableLoading(false);
          return message.error(res?.error);
        }
        dataSet(res.gridData);
      })

      .finally(() => {
        setIsTableLoading(false);
      });
  };

  const handleRequestMail = (apiType) => {
    if (!tokenId) {
      return message.error("Token id missing");
    }
    setRequestLoading((prev) => ({ ...prev, [apiType]: true }));
    getRequestMail({ token_id: tokenId, certificate: apiType })
      .then((res) => {
        if (res?.error) {
          setRequestLoading((prev) => ({ ...prev, [apiType]: false }));
          return message.error(res?.error?.message);
        }
        getOnBoardingApi();
        message.success(res?.message);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setRequestLoading((prev) => ({ ...prev, [apiType]: false }));
      });
  };

  const getNewGridDataPayloads = () => {
    const payload = {
      brief_risk_assessment: observations,
    };

    Object.keys(lablesMapping).forEach((key) => {
      const gridDataByKey = data.find((x) => x.property === key);
      const isMulti =
        key === "directors_officers" || key === "ubos" ? true : false;
      if (gridDataByKey) {
        payload[key] = {
          cdd_doc: mapDataValues(gridDataByKey, "cddReport", isMulti),
          id_doc: mapDataValues(gridDataByKey, "companyRegistration", isMulti),
          ubo_doc: mapDataValues(gridDataByKey, "UBOCertificate", isMulti),
          consistency: mapDataValues(gridDataByKey, "checks"),
        };
      } else {
        payload[key] = {
          cdd_doc: "N/A",
          id_doc: "N/A",
          ubo_doc: "N/A",
          consistency: "N/A",
        };
      }
    });

    return payload;
  };

  const mapDataValues = (data, field, isMulti = false) => {
    const values = data[field].map((item) => item.value);
    if (isMulti) {
      return values;
    } else {
      return values.join("");
    }
  };

  const handleSaveGrid = () => {
    if (!tokenId) {
      return message.error("Token id missing");
    }
    setIsSaveLoading(true);

    const gridDataPayload = getNewGridDataPayloads();

    saveGridData({ token_id: tokenId, gridData: gridDataPayload })
      .then((res) => {
        if (res?.error) {
          setIsSaveLoading(false);
          return message.error(res?.error?.message);
        }
        setIsSaveLoading(false);
        handleClose();
        message.success(res?.message);
        getOnBoardingApi();
      })
      .catch((error) => {
        setIsSaveLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <>
        <Drawer
          placement="right"
          className="AiReportViewerDrawer select-none"
          closable={false}
          onClose={handleClose}
          open={isOpen}
        >
          <div
            className="flex lg:hidden justify-end cursor-pointer pr-4 pt-4 pb-2 z-30 sticky top-0 bg-white"
            onClick={handleClose}
          >
            <IoMdClose
              size={24}
              className="text-gray-500  hover:text-primary flex justify-center items-center "
            />
          </div>
          <div className="flex lg:flex-row-reverse flex-col lg:h-full h-[calc(100vh-48px)] overflow-auto ">
            <div className="flex lg:w-[60%] w-full flex-col lg:ml-4">
              {!isPdfLoading && (
                <>
                  <div
                    className="lg:flex hidden justify-end cursor-pointer pr-4 pt-4 mb-2 sticky top-0 bg-white"
                    onClick={handleClose}
                  >
                    <IoMdClose
                      size={24}
                      className="text-gray-500  hover:text-primary flex justify-center items-center"
                    />
                  </div>

                  <div className="flex flex-col lg:gap-6 gap-4 mt-2 lg:h-[calc(100vh-47px)] lg:overflow-y-auto pr-4 pl-4 lg:pl-0  pb-4   ">
                    <div className="flex w-full flex-wrap-reverse gap-4">
                      <div className="flex xl:gap-4 gap-2">
                        {activeButton !== "CDD Report" && (
                          <>
                            {((activeButton === "Company Registration" &&
                              oldButtonList.poi) ||
                              (activeButton === "UBO Certificate" &&
                                oldButtonList.ubo)) && (
                              <>
                                <Button
                                  loading={saveTranslateLoading}
                                  onClick={() => handleSaveTranslation()}
                                  className="text-xs flex !border-[#ffc000] border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-[#ffc000] px-4 h-8 rounded-lg"
                                >
                                  Save translation
                                </Button>

                                <Button
                                  onClick={() => {
                                    handleCancelTranslation();
                                  }}
                                  className="flex text-xs !border-primary border-2 whitespace-nowrap font-semibold tracking-wide !text-primary !bg-white px-4 h-8 rounded-lg"
                                >
                                  Cancel
                                </Button>
                              </>
                            )}

                            {((activeButton === "Company Registration" &&
                              !oldButtonList.poi) ||
                              (activeButton === "UBO Certificate" &&
                                !oldButtonList.ubo)) && (
                              <Button
                                onClick={() => {
                                  checkPdfForText(PDF);
                                  if (pdfContainsText) {
                                    return message.info(
                                      "Please perform OCR before translating the scanned pdf."
                                    );
                                  } else {
                                    setIsOpenTranslate(true);
                                    setTranslateName(activeButton);
                                  }
                                }}
                                type="primary"
                                className="flex text-xs border-2 whitespace-nowrap font-semibold tracking-wide !text-white px-4 h-8 rounded-lg"
                              >
                                Translate
                              </Button>
                            )}
                          </>
                        )}
                      </div>

                      <div className="flex xl:gap-4 gap-2 justify-end ml-auto">
                        {buttonList.map((item, index) => (
                          <button
                            type="button"
                            key={index}
                            onClick={() => {
                              console.log(originalData);
                              console.log(
                                "PDF URL",
                                Object.keys(
                                  item.name === "Company Registration"
                                    ? originalData.poi
                                    : item.name === "UBO Certificate" &&
                                        originalData.ubo
                                ).length > 0
                                  ? item.name === "Company Registration"
                                    ? originalData.poi.translatedDoc
                                    : item.name === "UBO Certificate" &&
                                      originalData.ubo.translatedDoc
                                  : item.fileName
                              );
                              setPdfUrl(
                                Object.keys(
                                  item.name === "Company Registration"
                                    ? originalData.poi
                                    : item.name === "UBO Certificate" &&
                                        originalData.ubo
                                ).length > 0
                                  ? item.name === "Company Registration"
                                    ? originalData.poi.translatedDoc
                                    : item.name === "UBO Certificate" &&
                                      originalData.ubo.translatedDoc
                                  : item.fileName
                              );
                              setOriginalUrl(item.fileName);
                              handleCleanSearch();
                              setActiveButton(item.name);
                              setSelectedButton(
                                item.name === "Company Registration"
                                  ? "poi"
                                  : item.name === "UBO Certificate" && "ubo"
                              );
                            }}
                            className={`text-xs border-primary border-2 whitespace-nowrap font-semibold tracking-wide px-4 h-8 rounded-lg ${
                              activeButton === item.name
                                ? "text-primary bg-white"
                                : "text-white bg-primary"
                            }`}
                          >
                            {item.name}
                          </button>
                        ))}
                        <Button
                          type="primary"
                          // disabled={isTableLoading}
                          onClick={createGrid}
                          loading={isTableLoading}
                          className="text-xs flex !border-[#ffc000] border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-[#ffc000] px-4 h-8 rounded-lg"
                        >
                          Create Grid
                        </Button>{" "}
                      </div>
                    </div>

                    <TableReportGridAdmin
                      setSearchText={setSearchText}
                      PDF={baseUrl + "/" + PDF}
                      keepChanges={keepChanges}
                      isTableLoading={isTableLoading}
                      data={data}
                      setData={setData}
                      setClickedCell={setClickedCell}
                      clickedCell={clickedCell}
                      handleCleanSearch={handleCleanSearch}
                    />

                    {response && (
                      <div className="flex flex-wrap justify-end xl:gap-4 gap-2">
                        {keepChanges && (
                          <>
                            <Button
                              onClick={() => {
                                setDiscardChanges(true);
                                handleDiscardChanges();
                              }}
                              loading={discardChanges}
                              className="text-xs flex !border-primary border-2 whitespace-nowrap !bg-white font-semibold tracking-wide !text-primary px-4 h-8 rounded-lg"
                            >
                              Discard These Changes
                            </Button>

                            <button
                              type="button"
                              onClick={() => setKeepChanges(false)}
                              className="text-xs border-primary border-2 whitespace-nowrap font-semibold tracking-wide text-white bg-primary px-4 h-8 rounded-lg"
                            >
                              Keep These Changes
                            </button>
                          </>
                        )}
                        {!keepChanges && (
                          <Button
                            type="button"
                            disabled={isTableLoading}
                            onClick={() => setKeepChanges(true)}
                            className="text-xs !border-primary border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-primary px-4 h-8 rounded-lg"
                          >
                            Edit Grid
                          </Button>
                        )}
                      </div>
                    )}

                    {observations && (
                      <div>
                        <span className="font-bold">Observations:</span>{" "}
                        {observations}
                      </div>
                    )}

                    <div className="flex flex-wrap justify-end  xl:gap-4 gap-2 mt-auto">
                      {pdfContainsText && (
                        <Button
                          type="primary"
                          onClick={() => setIsOpenOCR(true)}
                          className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
                        >
                          Perform OCR
                        </Button>
                      )}

                      <Button
                        type="primary"
                        onClick={() => handleRequestMail("CDD")}
                        loading={requestLoading.CDD}
                        className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
                      >
                        Request new CDD
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleRequestMail("POI")}
                        loading={requestLoading.POI}
                        className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
                      >
                        Request new POI
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleRequestMail("UBO")}
                        loading={requestLoading.UBO}
                        className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
                      >
                        Request new UBO
                      </Button>

                      <Button
                        type="primary"
                        loading={isSaveLoading}
                        disabled={data.length === 0 || isTableLoading}
                        onClick={() => handleSaveGrid()}
                        className="text-xs flex cursor-pointer !border-[#ffc000] border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-[#ffc000] px-4 h-8 rounded-lg"
                      >
                        Save Grid
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="lg:w-[40%] w-full flex flex-col pl-4 pr-4 lg:pr-0 py-4">
              <div className="text text-center font-semibold mb-2 flex flex-col h-full">
                {activeButton} for {companyName}
                {((selectedButton === "poi" && originalList?.poi.length > 0) ||
                  (selectedButton === "ubo" &&
                    originalList?.ubo.length > 0)) && (
                  <div className="flex flex-col justify-start items-center">
                    {Object.keys(
                      selectedButton === "poi"
                        ? originalData.poi
                        : selectedButton === "ubo" && originalData.ubo
                    ).length > 0 && (
                      <div>
                        <span>
                          AI Translated from{" "}
                          {selectedButton === "poi"
                            ? originalData.poi.translatedFrom
                            : selectedButton === "ubo" &&
                              originalData.ubo.translatedFrom}{" "}
                          into{" "}
                          {selectedButton === "poi"
                            ? getLabelByValue(originalData.poi.translatedTo)
                            : selectedButton === "ubo" &&
                              getLabelByValue(
                                originalData.ubo.translatedTo
                              )}{" "}
                        </span>
                        <span className="mx-1">-</span>
                        <a
                          className="!text-blue-600"
                          onClick={() => {
                            setPdfUrl(
                              selectedButton === "poi"
                                ? buttonList.find(
                                    (button) => button.name === activeButton
                                  ).fileName
                                : selectedButton === "ubo" &&
                                    buttonList.find(
                                      (button) => button.name === activeButton
                                    ).fileName
                            );
                            setOriginalData((prevState) => ({
                              ...prevState,
                              [selectedButton]: {},
                            }));
                          }}
                        >
                          Show original document
                        </a>
                      </div>
                    )}

                    {Object.keys(
                      selectedButton === "poi"
                        ? originalData.poi
                        : selectedButton === "ubo" && originalData.ubo
                    ).length === 0 && (
                      <div>
                        <span>Show the document translated in</span>
                        <span className="mx-1">:</span>

                        {(selectedButton === "poi"
                          ? originalList?.poi || []
                          : selectedButton === "ubo"
                          ? originalList?.ubo || []
                          : []
                        ).map((value, index) => (
                          <a
                            key={index}
                            className="!text-blue-600 ml-1"
                            onClick={() => {
                              setOriginalData((prevState) => ({
                                ...prevState,
                                [selectedButton]: value,
                              }));
                              setPdfUrl(value.translatedDoc);
                            }}
                          >
                            {getLabelByValue(value.translatedTo)}
                            {(selectedButton === "poi"
                              ? originalList?.poi || []
                              : selectedButton === "ubo"
                              ? originalList?.ubo || []
                              : []
                            ).length -
                              1 ===
                            index
                              ? ""
                              : ", "}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {isPdfLoading ? (
                  <Spin className="mt-20" />
                ) : (
                  <PDFParser
                    PDFUrl={baseUrl + "/" + PDF}
                    searchText={searchText}
                    clearHighlights={clearHighlights}
                    setClearHighlights={setClearHighlights}
                  />
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </>
      {isOpenOCR && (
        <OCRSelectDrawer
          isOpenOCR={isOpenOCR}
          setIsOpenOCR={setIsOpenOCR}
          tokenId={tokenId}
          activeButton={activeButton}
          setPdfUrl={setPdfUrl}
          setButtonList={setButtonList}
          setOriginalUrl={setOriginalUrl}
        />
      )}
      {isOpenTranslate && (
        <TranslateDrawer
          isOpenTranslate={isOpenTranslate}
          handleClose={() => setIsOpenTranslate(false)}
          translateName={translateName}
          tokenId={tokenId}
          setOldButtonList={setOldButtonList}
          originalUrl={originalUrl}
          setOriginalList={setOriginalList}
          setOriginalData={setOriginalData}
          setPdfUrl={setPdfUrl}
        />
      )}
    </>
  );
};

export default AdminAiViewerDrawer;
