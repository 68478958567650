import { message, Spin } from "antd";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import NotFoundData from "../components/NotFoundData";
import { baseUrl, getAdminProfile } from "../services/services.profile";
import { useUserContext } from "../contexts/userContext";
import AdminAiViewerDrawer from "../components/shared/Drawer/AiViewerDrawer/adminAiViewerDrawer";

const ConnectionsOnboarding = () => {
  const isAdmin = localStorage.userId === "652943c69df2a7301b23c9ab";

  return isAdmin ? <AdminView /> : <OtherUsersView />;
};

export default ConnectionsOnboarding;

const OtherUsersView = () => {
  const { user, RoleManage, userData } = useUserContext();
  const [loading, setLoading] = useState(false);

  const [onBoarding, setOnBoarding] = useState([]);
  // const [profile, setProfile] = useState({});
  const [removeAccess, setRemoveAccess] = useState(
    user === RoleManage.ADMIN && userData.current_version === "AUTODESK"
      ? [RoleManage?.ADMIN]
      : [RoleManage?.MLRO]
  );

  const getOnBoardingApi = () => {
    setLoading(true);
    axios.get(baseUrl + "/api/onboarding/list").then((res) => {
      let array = res?.data?.data;
      array = res?.data?.data?.filter((item) => item?.status !== "confirmed");
      setOnBoarding(array);
      setLoading(false);
    });
  };

  const onBoardingLink = (item) => {
    const onBoardingbaseUrl = "https://clients.onboarding.ipass.id";
    window.open(onBoardingbaseUrl + "/#/" + item?.token_id, "_blank");
  };

  const removeOnboarding = async (item) => {
    const res = await axios
      .post(baseUrl + `/user/onboarding/delete`, {
        tokenId: item?.token_id,
      })
      .then((res) => {
        message.success("Onboarding remove Successfully");
        getOnBoardingApi();
      })
      .catch((err) => {
        message.error("Invalid Onboarding request");
      });
  };

  const sendReminderApi = async (item) => {
    const res = await axios
      .post(baseUrl + `/user/onboarding/resendemail`, {
        tokenId: item?.token_id,
      })
      .then((res) => {
        message.success("Email has been sent Successfully");
        getOnBoardingApi();
      })
      .catch((err) => {
        message.error("Invalid email");
      });
  };

  useEffect(() => {
    // Get current login user admin data
    getAdminProfile().then((adminRes) => {
      if (adminRes?.data) {
        // setProfile(adminRes?.data);
        getOnBoardingApi();
      }
    });
  }, []);

  return (
    <>
      {loading ? (
        <Spin className="w-full text-center mt-10" />
      ) : onBoarding.length === 0 ? (
        <NotFoundData
          key="no data"
          message="No onboardings are currently taking place"
        />
      ) : (
        <>
          {onBoarding.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className="w-full mb-6 flex-col sm:flex-row bg-[#f0f6fd] rounded-md p-4 flex justify-between gap-4"
                  key={index}
                >
                  <div className="flex flex-col text-[15px] justify-center items-start p-3">
                    <p className="font-bold mb-4">{item?.user_info?.email}</p>
                    <p
                      className="underline text-primary hover:cursor-pointer break-all mb-1"
                      onClick={() => {
                        onBoardingLink(item);
                      }}
                    >
                      {item?.receivedInfo?.respResult?.body?.split(
                        "Your artpass ID KYC onboarding link:"
                      )}
                    </p>
                    <div className="flex gap-1">
                      <p>requested on:</p>
                      <span className="font-semibold">
                        {item?.receivedInfo?.respResult?.date_created
                          ?.toString()
                          .slice(0, 16)}
                      </span>
                    </div>
                    <div className="flex gap-1 mb-2">
                      <p>Status:</p>
                      <span className="font-semibold">{item?.status}</span>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center gap-3 items-center text-[15px] px-5">
                    {/* Send reminder button  */}
                    <button
                      className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                      onClick={() => sendReminderApi(item)}
                    >
                      Send reminder
                    </button>

                    {/* Remove onboarding button  */}
                    {removeAccess.includes(user) && (
                      <button
                        className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                        onClick={() => removeOnboarding(item)}
                      >
                        Remove onboarding
                      </button>
                    )}
                  </div>
                </div>
                {/* </div> */}
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

const AdminView = () => {
  const { user, RoleManage, userData } = useUserContext();
  const [toggle, setToggle] = useState(true);
  const isAdmin = localStorage.userId === "652943c69df2a7301b23c9ab";

  const [removeAccess, setRemoveAccess] = useState(
    user === RoleManage.ADMIN && userData.current_version === "AUTODESK"
      ? [RoleManage?.ADMIN]
      : [RoleManage?.MLRO]
  );

  const [onBoardingAll, setOnBoardingAll] = useState([]);
  const [onBoardingPending, setOnBoardingPending] = useState([]);
  const [openAiGrid, setOpenAiGrid] = useState(false);
  const [singleRecordData, setSingleRecordData] = useState({
    tokenId: "",
    companyName: "",
  });

  const [loading, setLoading] = useState({
    all: false,
    internalChecking: false,
  });
  const switchName = toggle ? "internalChecking" : "all";
  const getOnBoardingApi = () => {
    setLoading((prev) => ({ ...prev, [switchName]: true }));
    const payload = {
      filter: {
        [switchName]: true,
      },
    };

    const apiCallUrl =
      isAdmin && axios.post(baseUrl + `/api/onboarding/all-list`, payload);

    apiCallUrl.then((res) => {
      let array = res?.data?.data;
      array = res?.data?.data?.filter((item) => item?.status !== "confirmed");
      toggle ? setOnBoardingPending(array) : setOnBoardingAll(array);
      setLoading((prev) => ({ ...prev, [switchName]: false }));
    });
  };

  const handleToggle = () => {
    setToggle(!toggle);
    setLoading({
      all: true,
      internalChecking: true,
    });
  };

  useEffect(() => {
    isAdmin && getOnBoardingApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  const onBoardingLink = (item) => {
    const onBoardingbaseUrl = "https://clients.onboarding.ipass.id";
    window.open(onBoardingbaseUrl + "/#/" + item?.token_id, "_blank");
  };

  const removeOnboarding = async (item) => {
    const res = await axios
      .post(baseUrl + `/user/onboarding/delete`, {
        tokenId: item?.token_id,
      })
      .then((res) => {
        message.success("Onboarding remove Successfully");
        getOnBoardingApi();
      })
      .catch((err) => {
        message.error("Invalid Onboarding request");
      });
  };

  const sendReminderApi = async (item) => {
    const res = await axios
      .post(baseUrl + `/user/onboarding/resendemail`, {
        tokenId: item?.token_id,
      })
      .then((res) => {
        message.success("Email has been sent Successfully");
        getOnBoardingApi();
      })
      .catch((err) => {
        message.error("Invalid email");
      });
  };

  const renderOnBoardingList = (list, isLoading) =>
    isLoading ? (
      <Spin className="w-full text-center mt-10" />
    ) : (
      <>
        {list.length === 0 ? (
          <NotFoundData
            key="no data"
            message="No onboardings are currently taking place"
          />
        ) : (
          list.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className="w-full mb-6 flex-col sm:flex-row bg-[#f0f6fd] rounded-md p-4 flex justify-between gap-4"
                key={index}
              >
                <div className="flex flex-col text-[15px] justify-center items-start p-3">
                  <p className="font-bold mb-4">{item?.user_info?.email}</p>
                  <p
                    className="underline text-primary hover:cursor-pointer break-all mb-1"
                    onClick={() => {
                      onBoardingLink(item);
                    }}
                  >
                    {item?.receivedInfo?.respResult?.body?.split(
                      "Your artpass ID KYC onboarding link:"
                    )}
                    {/* Onboarding link */}
                  </p>
                  <div className="flex gap-1">
                    <p>requested on:</p>
                    <span className="font-semibold">
                      {item?.receivedInfo?.respResult?.date_created
                        ?.toString()
                        .slice(0, 16)}
                    </span>
                  </div>
                  <div className="flex gap-1 mb-2">
                    <p>Status:</p>
                    <span className="font-semibold">{item?.status}</span>
                  </div>
                  {isAdmin && (
                    <>
                      {"poiVerifyToken" in item?.userCertificateData && (
                        <div className="flex gap-1">
                          {item?.userCertificateData?.poiVerifyToken ? (
                            <span className="font-semibold text-danger">
                              POI Doc Requested
                            </span>
                          ) : (
                            <span className="font-semibold text-[#70ad47]">
                              Requested POI Doc Received
                            </span>
                          )}
                        </div>
                      )}
                      {"uboVerifyToken" in item?.userCertificateData && (
                        <div className="flex gap-1 mb-1">
                          {item?.userCertificateData?.uboVerifyToken ? (
                            <span className="font-semibold text-danger">
                              UBO Doc Requested
                            </span>
                          ) : (
                            <span className="font-semibold text-[#70ad47]">
                              Requested UBO Doc Received
                            </span>
                          )}
                        </div>
                      )}

                      {"cddVerifyToken" in item?.userCertificateData && (
                        <div className="flex gap-1 mb-1">
                          {item?.userCertificateData?.cddVerifyToken ? (
                            <span className="font-semibold text-danger">
                              CDD Requested
                            </span>
                          ) : (
                            <span className="font-semibold text-[#70ad47]">
                              Requested CDD Received
                            </span>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="flex flex-col justify-center gap-3 items-center text-[15px] px-5">
                  {/* Send ai grid button  */}
                  <button
                    className="border bg-primary text-white px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                    onClick={() => {
                      setOpenAiGrid(true);
                      setSingleRecordData({
                        tokenId: item?.token_id,
                        companyName: item?.user_info?.company?.name,
                      });
                    }}
                  >
                    Ai Grid
                  </button>

                  {/* Send reminder button  */}
                  <button
                    className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                    onClick={() => sendReminderApi(item)}
                  >
                    Send reminder
                  </button>

                  {/* Remove onboarding button  */}
                  {removeAccess.includes(user) && (
                    <button
                      className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                      onClick={() => removeOnboarding(item)}
                    >
                      Remove onboarding
                    </button>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))
        )}
      </>
    );

  return (
    <>
      <>
        {isAdmin && (
          <div className="flex justify-start items-center gap-5 pr-5 mb-4">
            <p className="text-xl font-bold">All</p>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={toggle}
                onChange={handleToggle}
                readOnly
              />
              <div className="w-11 h-6 bg-blue-600 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 "></div>
            </label>
            <p className="text-xl font-bold">Internal Checking Pending</p>
          </div>
        )}

        {toggle
          ? renderOnBoardingList(onBoardingPending, loading.internalChecking)
          : renderOnBoardingList(onBoardingAll, loading.all)}
      </>

      {openAiGrid && (
        <AdminAiViewerDrawer
          isOpen={openAiGrid}
          handleClose={() => setOpenAiGrid(false)}
          singleRecordData={singleRecordData}
          getOnBoardingApi={getOnBoardingApi}
        />
      )}
    </>
  );
};
